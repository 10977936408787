import React, { Fragment, useState } from 'react';
import IconMusic from '@assets/images/music-icon.png';
import IconMusicStop from '@assets/images/music-stop-icon.png';
import MusicBacksound from '@assets/music/hon-ca-yeu.mp3';

import { styMusicFloating } from './styles';

var $ = require( "jquery" );

function FloatingMusic() {
  const [play, setPlay] = useState(true);

  const toggleMusic = () => {
    var $rota = $('.icon-music')

    const myAudio = document.getElementById('myAudio');
    /**
     * This function built-in with html5 function
     * doc: https://www.w3schools.com/tags/ref_av_dom.asp
     */
    if (play) {
      clearInterval($rota.data('rotating'));
      $rota.data('rotating', false)
      myAudio.pause();
    } else {
      $rota.data('rotating', setInterval(function(){
        var degree = $rota.data('degree') || 0;
        $rota.css({ transform: 'rotate(' + degree + 'deg)'});
        $rota.data('degree', ++degree)
    }, 15));
      myAudio.play();
    }

    setPlay(!play);
  };

  return (
    <Fragment>
      <div css={styMusicFloating}>
        <audio id="myAudio" loop className="hide">
          <source src={MusicBacksound} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>

        <div onClick={toggleMusic}>
          <img
            src={IconMusic}
            className="icon-music"
            alt="icon-music"
            title={`${play ? 'ZiFuong' : 'Hana'}`}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default FloatingMusic;
